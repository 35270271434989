import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@chakra-ui/react';

import '../css/upcoming.css';

export default function Upcoming() {
  const [upcomingAnime, setUpcomingAnime] = useState([]);
  const [limit, setUppendsLimit] = useState(21);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setIsPage] = useState(1);

  function UppendLimit() {
    if (limit < 25) {
      setUppendsLimit(prevLimit => Math.min(prevLimit + 4, 25));
    } else {
      UppendPage(); // If limit is 25, move to the next page
    }
  }

  function UppendPage() {
    setIsPage(prevPage => prevPage + 1);
    setUppendsLimit(12); // Reset limit to 12 for the new page
  }

  useEffect(() => {
    const fetchUpcomingAnime = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `https://api.jikan.moe/v4/seasons/upcoming?limit=${limit}&page=${page}`
        );
        const data = await response.json();

        // If moving to a new page, append data; otherwise, replace it
        setUpcomingAnime(prevAnime =>
          limit === 12 ? data.data : [...prevAnime, ...data.data]
        );
      } catch (error) {
        console.error('Error fetching episodes:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const debounceFetch = setTimeout(fetchUpcomingAnime, 500); // 500ms debounce

    return () => clearTimeout(debounceFetch); // Clear the timeout if limit changes before the time is up
  }, [limit, page]); // Re-run effect if either limit or page changes

  return (
    <div className="upcoming_anime_container">
      <div className="upcoming_anime_content">
        {upcomingAnime.map(anime => (
          <div
            key={anime.mal_id}
            style={{
              backgroundImage: `linear-gradient(to top, rgba(31,31,31,1) 0%, rgba(31,31,31,0) 50%),url(${anime.images.webp.large_image_url})`,
            }}
            className="upcoming_anime"
          >
            <div className="upcoming_anime_info">
              {anime.approved === true ? (
                <p className="confirmed">confirmed</p>
              ) : (
                <p className="unconfirmed">unconfirmed</p>
              )}
            </div>
          </div>
        ))}
      </div>
      <button onClick={UppendLimit} disabled={isLoading}>
        {isLoading ? (
          <CircularProgress isIndeterminate color="#ff007f" />
        ) : (
          'Load more upcoming'
        )}
      </button>
    </div>
  );
}
