import React, { useEffect, useRef, useState } from 'react';
import '../css/recent.css';
import { FaLongArrowAltRight } from 'react-icons/fa';

export default function TopAiring() {
  const scrollContainerRef = useRef(null);
  const [episodes, setEpisodes] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    const fetchEpisodes = async () => {
      try {
        const response = await fetch(
          'https://animeguricha.vercel.app/anime/gogoanime/top-airing'
        );
        const data = await response.json();
        setEpisodes(data.results);
      } catch (error) {
        console.error('Error fetching episodes:', error);
      }
    };

    fetchEpisodes();
  }, []);

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const scrollContainer = scrollContainerRef.current;
      const itemWidth = scrollContainer.firstChild.offsetWidth;
      const scrollLeft = scrollContainer.scrollLeft;
      const currentIndex = Math.round(scrollLeft / itemWidth);

      console.log(currentIndex);
    }
  };

  function limitStringLength(inputString) {
    const maxLength = 'Isekai Shikkakuuuuuu'.length; // 15 characters
    if (inputString.length > maxLength) {
      return inputString.slice(0, maxLength - 3) + '...'; // Adjust to leave space for '...'
    }
    return inputString;
  }

  return (
    <div className="recent">
      <div className="recent_arrow top_airing">
        <p>Most watched Anime</p>
        <FaLongArrowAltRight className="icon" />
      </div>
      <div
        ref={scrollContainerRef}
        onScroll={handleScroll}
        style={{
          display: 'flex',
          overflowX: 'auto',
          scrollSnapType: 'x mandatory',
          width: '100%',
          padding: '20px',
          boxSizing: 'border-box',
          gap: '20px',
        }}
        className="recent_scroll"
      >
        {episodes.map(episode => (
          <div key={episode.id} className="recent_container">
            <div className="image_container">
              <div
                className="image top-image"
                style={{
                  backgroundImage: `linear-gradient(to top, rgba(31,31,31,1) 0%, rgba(31,31,31,0) 100%),url(${episode.image})`,
                }}
                onClick={() => {
                  window.location.href = `/anime/${episode.title}`;
                }}
              >
                <p>{limitStringLength(episode.title)}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
