import React, { useEffect, useRef, useState } from 'react';
import '../css/recent.css';
import { FaLongArrowAltRight } from 'react-icons/fa';

export default function Recent() {
  const scrollContainerRef = useRef(null);
  const [episodes, setEpisodes] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    const fetchEpisodes = async () => {
      try {
        const response = await fetch(
          'https://animeguricha.vercel.app/anime/gogoanime/recent-episodes'
        );
        const data = await response.json();
        setEpisodes(data.results);
      } catch (error) {
        console.error('Error fetching episodes:', error);
      }
    };

    fetchEpisodes();
  }, []);

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const scrollContainer = scrollContainerRef.current;
      const itemWidth = scrollContainer.firstChild.offsetWidth;
      const scrollLeft = scrollContainer.scrollLeft;
      // Removed the unused variable currentIndex
      Math.round(scrollLeft / itemWidth);
    }
  };

  return (
    <div className="recent">
      <div className="recent_arrow">
        <p>recents</p>
        <FaLongArrowAltRight className="icon" />
      </div>
      <div
        ref={scrollContainerRef}
        onScroll={handleScroll}
        style={{
          display: 'flex',
          overflowX: 'auto',
          scrollSnapType: 'x mandatory',
          width: '100%',
          padding: '20px',
          boxSizing: 'border-box',
          gap: '20px',
        }}
        className="recent_scroll"
      >
        {episodes.map(episode => (
          <div key={episode.id} className="recent_container">
            <div className="image_container">
              <div
                className="image"
                style={{
                  backgroundImage: `linear-gradient(to bottom, rgba(31,31,31,1) 0%, rgba(31,31,31,0) 20%),url(${episode.image})`,
                }}
                onClick={() => {
                  window.location.href = `/anime/watch/${episode.episodeId}`;
                }}
              >
                <div className="episode_container">
                  <p>epi {episode.episodeNumber}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
