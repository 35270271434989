import { FaDiscord } from 'react-icons/fa';
import { AiOutlineWhatsApp } from 'react-icons/ai';

import '../css/Footer.scss';
import { GiFlowers } from 'react-icons/gi';

export default function Footer() {
  return (
    <div className="footer-container">
      <div className="brand">
        <div className="brand-info">
          <div className="brand-title">
            <h1>
              <GiFlowers className="icon" />
              SakuraScroll
            </h1>
          </div>
          <a href="/">Terms & Privacy</a>
          <a href="/">Faq</a>
        </div>
        <div className="brand-footer">
          <h3>Copyright &copy; sakurascroll. All rights reserved</h3>
          <p>
            This site does not store any files on its server. All contents are
            provided by non-affiliated third parties.
          </p>
        </div>
      </div>
      <div className="social">
        <a href="/">
          <FaDiscord className="discord" />
        </a>
        <a href="/">
          <AiOutlineWhatsApp className="whatsapp" />
        </a>
      </div>
    </div>
  );
}
