import { Tabs, TabList, Tab, TabPanels } from '@chakra-ui/react'; // Removed TabPanel
import { useState, useEffect } from 'react';
import '../css/schedule.css';

const daysOfWeek = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

const daysOfWeekMini = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'];

export default function Schedule() {
  const [animes, setAnimes] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState(new Date().getDay());
  const [isCooldown, setIsCooldown] = useState(false); // Cooldown state

  useEffect(() => {
    const fetchAnime = async () => {
      try {
        const dayOfWeek = daysOfWeek[activeTabIndex];
        const response = await fetch(
          `https://api.jikan.moe/v4/schedules?filter=${dayOfWeek}`
        );
        const data = await response.json();
        setAnimes(data.data);
      } catch (error) {
        console.error('Error fetching episodes:', error);
      }
    };

    fetchAnime();
  }, [activeTabIndex]);

  const handleTabChange = index => {
    if (isCooldown) return; // Prevent tab change if cooldown is active

    setActiveTabIndex(index);
    setIsCooldown(true); // Activate cooldown

    // Disable tabs for 1 second (1000ms)
    setTimeout(() => {
      setIsCooldown(false); // Re-enable tabs after cooldown
    }, 1000);
  };

  return (
    <div className="tab_container">
      <p>Estimated Schedule</p>
      <Tabs
        className="tabs"
        variant="soft-rounded"
        colorScheme="green"
        index={activeTabIndex}
        onChange={handleTabChange}
        isDisabled={isCooldown} // Disable all tabs if cooldown is active
      >
        <TabList className="tablist">
          {daysOfWeekMini.map((day, index) => (
            <Tab
              key={index}
              className={index === activeTabIndex ? 'tab selected' : 'tab'}
            >
              {day}
            </Tab>
          ))}
        </TabList>
        <TabPanels className="tabPanels">
          {animes.map(anime => (
            <div className="tab_panels" key={anime.mal_id}>
              <div className="time">
                <p>{anime.broadcast.time}</p>
                <h3>{anime.title}</h3>
              </div>
            </div>
          ))}
        </TabPanels>
      </Tabs>
    </div>
  );
}
