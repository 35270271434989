import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, NavLink } from 'react-router-dom';

import Home from './pages/HomePage';

import './css/main.css';
import './css/header.css';
import './css/query.css';

import { BsViewList } from 'react-icons/bs';
import { RiMenu3Fill, RiCloseFill } from 'react-icons/ri';
import { HomeOption, Target, Technology } from 'grommet-icons';
import Footer from './components/Footer';
import Upcoming from './pages/Upcoming';
import Anime from './pages/Anime';
import Under from './pages/Under';
import VideoPage from './components/Video';

function App() {
  const [open, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [currentQuery, setCurrentQuery] = useState('');

  const openMenu = () => {
    setIsOpen(!open);
  };

  const handleSearchChange = e => {
    const inputValue = e.target.value;
    setQuery(inputValue);

    // Immediate results clearing if input is empty
    if (inputValue.trim() === '') {
      setResults([]);
      setCurrentQuery(''); // Ensure no outdated query is kept
      return;
    }

    // Update current query to the new value
    setCurrentQuery(inputValue);
  };

  useEffect(() => {
    // Only fetch results if query has a value
    if (query.trim() === '') {
      setResults([]);
      return;
    }

    const fetchResults = async () => {
      try {
        const response = await fetch(
          `https://animeguricha.vercel.app/anime/gogoanime/${decodeURIComponent(
            query
          )}`
        );
        const data = await response.json();

        // Check if current query matches the fetched results query
        if (currentQuery === query) {
          setResults(data.results || []);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setResults([]);
      }
    };

    fetchResults();

    // Optional cleanup function in case of rapid input changes
    return () => {
      setResults([]);
    };
  }, [query, currentQuery]);

  return (
    <BrowserRouter>
      <div className="header_container">
        <div className="logo_container">
          <p>
            SakuraScroll<span className="beta">beta</span>
          </p>
        </div>
        <div className="input_container">
          <input
            placeholder="what's on your mind ?...... "
            type="search"
            value={query}
            onChange={handleSearchChange}
          />
          {query && results.length > 0 && (
            <div className="input_container_results">
              {results.map(result => (
                <div
                  key={result.id}
                  className="result_item"
                  onClick={() => {
                    window.location.href = `/anime/${result.id}`;
                  }}
                >
                  <div
                    className="result_item_image"
                    style={{ backgroundImage: `url(${result.image})` }}
                  ></div>
                  <div className="result_item_info">
                    <p>{result.title}</p>
                    <p className="status">{result.status}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="nav_container desktop">
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? 'active' : 'a')}
          >
            <HomeOption color="#f5f5f5" size="small" />
            home
          </NavLink>
          <NavLink
            to="/genres"
            className={({ isActive }) => (isActive ? 'active' : 'a')}
          >
            <Target color="#f5f5f5" size="small" />
            genres
          </NavLink>
          <NavLink
            to="/upcoming"
            className={({ isActive }) => (isActive ? 'active' : 'a')}
          >
            <Technology color="#f5f5f5" size="small" />
            upcoming
          </NavLink>
          <NavLink
            to="/anime-list"
            className={({ isActive }) => (isActive ? 'active' : 'a')}
          >
            <BsViewList className="icon" />
            anime list
          </NavLink>
        </div>
        {open && (
          <div className="nav_container mobile">
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? 'active' : 'a')}
            >
              <HomeOption color="#f5f5f5" size="small" />
              home
            </NavLink>
            <NavLink
              to="/genres"
              className={({ isActive }) => (isActive ? 'active' : 'a')}
            >
              <Target color="#f5f5f5" size="small" />
              genres
            </NavLink>
            <NavLink
              to="/upcoming"
              className={({ isActive }) => (isActive ? 'active' : 'a')}
            >
              <Technology color="#f5f5f5" size="small" />
              upcoming
            </NavLink>
            <NavLink
              to="/anime-list"
              className={({ isActive }) => (isActive ? 'active' : 'a')}
            >
              <BsViewList className="icon" />
              anime list
            </NavLink>
          </div>
        )}
        <div className="burger_container">
          {open ? (
            <RiCloseFill className="icon" onClick={openMenu} />
          ) : (
            <RiMenu3Fill className="icon" onClick={openMenu} />
          )}
        </div>
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/upcoming" element={<Upcoming />} />
        <Route path="/genres" element={<Under />} />
        <Route path="/anime-list" element={<Under />} />
        <Route path="/anime/watch/:id" element={<VideoPage />} />
        <Route path="/anime/:id" element={<Anime />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
