import '../css/under.css';

export default function Under() {
  return (
    <div
      style={{
        height: '50rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Chilling Nightime, sans-serif',
      }}
    >
      <h1 style={{ fontFamily: 'Chilling Nightime, sans-serif', fontSize: '35pt' }}>
        Lemme Cook !!!
      </h1>
    </div>
  );
}
