import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  CircularProgress,
} from '@chakra-ui/react';

import '../css/anime.css';

export default function Anime() {
  const { id } = useParams();

  const [animeId, setAnimeId] = useState(null);
  const [animeInfo, setAnimeInfo] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [loading, setLoading] = useState(true); // For preloader

  useEffect(() => {
    const fetchAnime = async () => {
      try {
        const response = await fetch(
          `https://animeguricha.vercel.app/anime/gogoanime/${decodeURIComponent(
            id
          )}`
        );
        const result = await response.json();
        if (result.results && result.results.length > 0) {
          setAnimeId(result.results[0].id);
        } else {
          console.error('No anime found');
        }
      } catch (error) {
        console.error('Error fetching anime:', error);
      }
    };

    fetchAnime();
  }, [id]);

  useEffect(() => {
    if (animeId) {
      const fetchAnimeInfo = async () => {
        try {
          setLoading(true); // Start loading
          const response = await fetch(
            `https://animeguricha.vercel.app/anime/gogoanime/info/${animeId}`
          );
          const result = await response.json();
          console.log(result);
          setAnimeInfo(result);
        } catch (error) {
          console.error('Error fetching anime info:', error);
        } finally {
          setLoading(false); // End loading
        }
      };

      fetchAnimeInfo();
    }
  }, [animeId]);

  const tabs = ['Episodes', 'About this', 'Characters', 'Artwork'];

  function extractTitle(str) {
    // Remove leading and trailing white spaces
    let cleanedStr = str.trim();

    // Find the first part before the comma
    let title = cleanedStr.split(',')[0];

    // Remove any extra white spaces or newlines
    return title.replace(/\s+/g, ' ').trim();
  }

  function countItems(arr) {
    return arr.length;
  }

  return (
    <div className="anime_container">
      {loading ? (
        <div className="loading_container">
          <CircularProgress isIndeterminate color="#ff0080" />
        </div>
      ) : (
        <>
          <div className="anime_container_info">
            <div
              className="anime_cover"
              style={{
                backgroundImage: `linear-gradient(to top, rgba(31,31,31,1) 20%, rgba(31,31,31,0) 100%),url(${
                  animeInfo?.image || '../css/bg.jpg'
                })`,
              }}
            >
              <div className="anime_info">
                <div className="anime_info_image_container">
                  <div
                    className="anime_info_image"
                    style={{
                      backgroundImage: `url(${
                        animeInfo?.image || '../css/bg.jpg'
                      })`,
                    }}
                  />
                </div>
                <div className="anime_info_infos">
                  <div className="anime_info_titles">
                    <h4>{extractTitle(animeInfo?.otherName) || 'Yep'}</h4>
                    <h1>{animeInfo?.title || 'Damn! even the title'}</h1>
                  </div>
                  <p
                    className={
                      animeInfo?.status === 'Completed' ? 'complete' : 'ongoing'
                    }
                  >
                    {animeInfo?.status || 'Not even the status'}
                  </p>
                  <div className="anime_info_genres">
                    {animeInfo?.genres && animeInfo.genres.length > 0 ? (
                      animeInfo.genres
                        .slice(0, 5)
                        .map((genre, index) => <p key={index}>{genre}</p>)
                    ) : (
                      <p>could not find the above, this is expected</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="anime_container_episodes">
            <Tabs
              className="tabs"
              index={activeTabIndex}
              onChange={index => setActiveTabIndex(index)}
            >
              <TabList className="tablist">
                {tabs.map((tab, index) => (
                  <Tab
                    key={index}
                    className={
                      index === activeTabIndex ? 'tab selected' : 'tab'
                    }
                  >
                    {tab}
                  </Tab>
                ))}
              </TabList>

              <TabPanels className="tabpanels">
                <TabPanel>
                  <div className="tabpanel">
                    {animeInfo?.episodes && animeInfo.episodes.length > 0 ? (
                      animeInfo.episodes.map(episode => (
                        <div
                          key={episode.id}
                          style={{
                            backgroundImage: `linear-gradient(rgba(31,31,31,0), rgba(31,31,31,1)),url(${
                              episode.image || '../css/bg.jpg'
                            })`,
                          }}
                          className="episode"
                          onClick={() => {
                            window.location.href = `/anime/watch/${episode.id}`;
                          }}
                        >
                          <p>Episode {episode.number}</p>
                        </div>
                      ))
                    ) : (
                      <p>If i were you, i wouldve punched the dev for this</p>
                    )}
                  </div>
                </TabPanel>
                <TabPanel>
                  <div
                    className="mobile_para"
                    style={{
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: '12pt',
                      fontWeight: '300',
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        animeInfo?.description ||
                        'You thought you could get atleast the description, too bad its nothing!!',
                    }}
                  />
                  <div className="about_this">
                    <div className="about">
                      <h3>Type</h3>
                      <h4>{animeInfo?.type || 'definetly not kids'}</h4>
                    </div>
                    <div className="about">
                      <h3>Rating</h3>
                      <h4>{animeInfo?.rating || 'above 50'}</h4>
                    </div>
                    <div className="about">
                      <h3>Total Episodes</h3>
                      <h4>
                        {countItems(animeInfo?.episodes) ||
                          '1 + 1 = 11, prove me wrong'}
                      </h4>
                    </div>
                    <div className="about">
                      <h3>Audio</h3>
                      <h4>
                        {animeInfo?.subOrDub
                          ? `${animeInfo.subOrDub}bed`
                          : 'haaaaaa!'}
                      </h4>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="tab_characters">
                    {animeInfo?.characters &&
                    animeInfo.characters.length > 0 ? (
                      animeInfo.characters.map(character => (
                        <div className="characters" key={character.id}>
                          <div className="character_anime">
                            <div
                              style={{
                                backgroundImage: `url(${
                                  character.image || '../css/bg.jpg'
                                })`,
                              }}
                              className="character_anime_image"
                            ></div>
                            <div className="character_anime_info">
                              <p>{character.role || 'Unknown role'}</p>
                              <h1>
                                {character.name?.userPreferred ||
                                  'Unknown name'}
                              </h1>
                            </div>
                          </div>
                          <div className="character_voice">
                            {animeInfo?.subOrDub === 'sub'
                              ? character.voiceActors?.[0] && (
                                  <>
                                    <div
                                      style={{
                                        backgroundImage: `url(${
                                          character.voiceActors[0]?.image ||
                                          '../css/bg.jpg'
                                        })`,
                                      }}
                                      className="character_voice_image"
                                    ></div>
                                    <div className="character_voice_info">
                                      <p>
                                        {character.voiceActors[0]?.language ||
                                          'Unknown language'}
                                      </p>
                                      <h1>
                                        {character.voiceActors[0]?.name
                                          ?.userPreferred || 'Unknown actor'}
                                      </h1>
                                    </div>
                                  </>
                                )
                              : character.voiceActors?.[1] && (
                                  <>
                                    <div
                                      className="character_voice_image"
                                      style={{
                                        backgroundImage: `url(${
                                          character.voiceActors[1]?.image ||
                                          '../css/bg.jpg'
                                        })`,
                                      }}
                                    ></div>
                                    <div className="character_voice_info">
                                      <p>
                                        {character.voiceActors[1]?.language ||
                                          'Unknown language'}
                                      </p>
                                      <h1>
                                        {character.voiceActors[1]?.name
                                          ?.userPreferred || 'Unknown actor'}
                                      </h1>
                                    </div>
                                  </>
                                )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>Still in Beta ! update coming soon</p>
                    )}
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="artwork_container">
                    <p>This is a wallpaper section, still in BETA</p>
                    <div>
                      {animeInfo?.artwork && animeInfo.artwork.length > 0 ? (
                        animeInfo.artwork
                          .slice(0, 50)
                          .filter(artwork => artwork.type === 'banner')
                          .map((artwork, index) => (
                            <div
                              key={index}
                              style={{
                                backgroundImage: `url(${
                                  artwork.img || '../css/bg.jpg'
                                })`,
                              }}
                              className="artwork_image"
                            />
                          ))
                      ) : (
                        <p>...........</p>
                      )}
                    </div>

                    <p>Load more & Download function will be soon available</p>
                  </div>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </div>
        </>
      )}
    </div>
  );
}
