import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import '../css/animeplayer-ad.css';
import '../css/animeplayer.css';
import '../css/vid.css';

import { MediaPlayer, MediaProvider } from '@vidstack/react';
import {
  PlyrLayout,
  plyrLayoutIcons,
} from '@vidstack/react/player/layouts/plyr';
import { CircularProgress } from '@chakra-ui/react';

export default function VideoPage() {
  const { id } = useParams();

  const [animeId, setAnimeId] = useState(null);
  const [animeInfo, setAnimeInfo] = useState(null);
  const [link, setLink] = useState(null);

  useEffect(() => {
    const fetchAnime = async () => {
      try {
        const response = await fetch(
          `https://animeguricha.vercel.app/meta/anilist/watch/${id}`
        );
        const result = await response.json();
        console.log('API Result:', result);
        if (result.sources && result.sources.length > 0) {
          const defaultSource = result.sources.find(
            source => source.quality === 'default'
          );
          if (defaultSource) {
            setLink(defaultSource.url);
          } else {
            console.error('Default quality source not found');
          }
        } else {
          console.error('No anime found');
        }
      } catch (error) {
        console.error('Error fetching anime:', error);
      }
    };

    fetchAnime();
  }, [id]);

  useEffect(() => {
    function removeEpisodeNumber(str) {
      return str.replace(/-episode-\d+$/, '');
    }
    const fetchAnime = async () => {
      try {
        const response = await fetch(
          `https://animeguricha.vercel.app/anime/gogoanime/${decodeURIComponent(
            removeEpisodeNumber(id)
          )}`
        );
        const result = await response.json();
        if (result.results && result.results.length > 0) {
          setAnimeId(result.results[0].id);
        } else {
          console.error('No anime found');
        }
      } catch (error) {
        console.error('Error fetching anime:', error);
      }
    };

    fetchAnime();
  }, [id]);

  useEffect(() => {
    if (animeId) {
      const fetchAnimeInfo = async () => {
        try {
          const response = await fetch(
            `https://animeguricha.vercel.app/anime/gogoanime/info/${animeId}`
          );
          const result = await response.json();
          console.log(result);
          setAnimeInfo(result);
        } catch (error) {
          console.error('Error fetching anime info:', error);
        }
      };

      fetchAnimeInfo();
    }
  }, [animeId, id]);

  return (
    <div className="player-container">
      {link ? (
        <MediaPlayer
          src={link}
          viewType="video"
          streamType="on-demand"
          logLevel="warn"
          crossOrigin="anonymous" // Make sure the player can fetch cross-origin sources
          playsInline
          title="SakuraScroll"
          poster={animeInfo?.image || ''} // Use the active episode's image as poster
          className="custom-media-player"
        >
          <MediaProvider />
          <PlyrLayout icons={plyrLayoutIcons} />
        </MediaPlayer>
      ) : (
        <div className="loading_container">
          <CircularProgress isIndeterminate color="#ff0080" />
        </div>
      )}
      <div className="episode">
        {animeInfo?.episodes.map(episode => (
          <div
            key={episode.id}
            onClick={() => {
              window.location.href = `/anime/watch/${episode.id}`;
            }}
            className={episode.id === id ? 'active-episode' : ''}
          >
            <p>episode {episode.number}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
