import { useEffect, useState } from 'react';
import Popular from '../components/Popular';
import '../css/embla.css';
import Recent from '../components/Recent';
import TopAiring from '../components/TopAirs';
import Schedule from '../components/Schedule';
import Movie from '../components/Movie';

const OPTIONS = { align: 'start', loop: true };

export default function Home() {
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    const fetchAnime = async () => {
      try {
        const response = await fetch(
          'https://animeguricha.vercel.app/meta/anilist/popular'
        );
        const result = await response.json();
        setSlides(result.results); // Assuming result.data is the array of anime data
      } catch (error) {
        console.error('Error fetching anime:', error);
      }
    };

    fetchAnime();
  }, []);

  return (
    <div className="main_container">
      <Popular slides={slides} options={OPTIONS} />
      <Recent />
      <TopAiring />
      <Movie />
      <Schedule />
    </div>
  );
}
