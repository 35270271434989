import React, { useCallback } from 'react';
import { DotButton, useDotButton } from './PopularDot';
import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';

import '../css/embla_content.css';

const Popular = ({ slides, options }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [Autoplay()]);

  const onNavButtonClick = useCallback(emblaApi => {
    const autoplay = emblaApi?.plugins()?.autoplay;
    if (!autoplay) return;

    const resetOrStop =
      autoplay.options.stopOnInteraction === false
        ? autoplay.reset
        : autoplay.stop;

    resetOrStop();
  }, []);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(
    emblaApi,
    onNavButtonClick
  );

  function limitText(text, limit) {
    if (text.length > limit) {
      return text.substring(0, limit) + '...';
    }

    return text;
  }

  return (
    <section className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {slides.map((slide, index) => (
            <div
              className="embla__slide"
              style={{
                backgroundImage: `linear-gradient(to top, rgba(31,31,31,1) 0%, rgba(31,31,31,0) 100%),url(${slide.cover})`,
              }}
              key={slide.malId}
            >
              <div className="embla_slide_content">
                <div className="content_info">
                  <div className="content_info_top">
                    <p className="type">{slide.type}</p>
                    <p className="year">{slide.releaseDate}</p>
                  </div>
                  <h1>{slide.title?.romaji}</h1>
                  <p className="content_info_description">
                    {limitText(slide.description, 500)}
                  </p>
                </div>
                <div className="content_buttons">
                  <button
                    className="content_buttons_btn"
                    onClick={() => {
                      window.location.href = `/anime/${slide.title?.romaji}`;
                    }}
                  >
                    Watch
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="embla__controls">
        <div className="embla__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={'embla__dot'.concat(
                index === selectedIndex ? ' embla__dot--selected' : ''
              )}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Popular;
